<script setup lang="ts">
type Item = { src: string, title: string, link?: string }

const data: Item[] = [{
  src: '/img/free-shipping-icon.svg',
  title: 'ارسال رایگان به سراسر ایران'
}, {
  src: '/img/free-install-icon.svg',
  title: 'نصب رایگان و ارائه خدمات در محل',
  link: '/support'
}, {
  src: '/img/online-payment-icon.svg',
  title: 'پرداخت امن از درگاه بانکی'
}, {
  src: '/img/guarantee-icon.svg',
  title: 'گارانتی معتبر و خدمات مادام‌العمر'
}, {
  src: '/img/support-icon.svg',
  title: 'پشیبانی آنلاین و تلفنی',
  link: '/support'
}]
</script>

<template lang="pug">
.benefits
  .container(
    class="mx-auto flex flex-wrap justify-center gap-2"
    class="lg:grid lg:grid-cols-5 lg:justify-items-center"
  )
    template(v-for="item in data")
      HomeBenefitItem(:src="item.src" :title="item.title" :link="item.link")
</template>
