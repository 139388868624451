<script setup lang="ts">
const modules = [
  SwiperAutoplay,
  SwiperPagination,
  SwiperNavigation,
  SwiperEffectCoverflow,
]

const coverflow = {
  rotate: 10,
  stretch: 0,
  depth: 300,
  modifier: 2,
  slideShadows: true,
}
</script>

<template lang="pug">
.home-slider-lg(class="h-[35rem]")
  Swiper(
    :modules="modules"
    :pagination="{ clickable: true }"
    :slidesPerView="'auto'"
    :loop="true"
    :navigation="true"
    :autoplay="{ delay: 6000 }",
    :grabCursor="true"
    :centeredSlides="true"
    :effect="'coverflow'"
    :coverflowEffect="coverflow"
    class="h-full"
  )
    SwiperSlide
      a(href="#")
        figure
          img(src="/slides/slide-01.webp" alt="جعبه‌های پستی از آسمان با چتر به سمت پایین حرکت میکنند. یکی از جعبه‌ها با بال پرواز میکند")
    SwiperSlide
      a(href="#")
        figure
          img(src="/slides/slide-03.webp" loading="lazy" alt="گوشی پزشکی")
    SwiperSlide
      a(href="#")
        figure
          img(src="/slides/slide-02.webp" loading="lazy" alt="دم پیچیده شده آفتاب‌پرست")
    SwiperSlide
      a(href="#")
        figure
          img(src="/slides/slide-04.webp" loading="lazy" alt="کودک خردسال با عینک درحال آب نوشیدن از لیوان بزرگ شیشه‌ای")
    SwiperSlide
      a(href="#")
        figure
          img(src="/slides/slide-05.webp" loading="lazy" alt="تصویه آب با چای ساف کن")
</template>

<style lang="stylus">
.home-slider-lg
  --swiper-theme-color #555
  --swiper-pagination-bullet-horizontal-gap 3px
  --swiper-pagination-bullet-size 7px
  .swiper
    .swiper-slide
      @apply h-full overflow-hidden rounded-2xl shadow
      max-width calc(1140px - 2rem)
      height calc(100% - .5rem)
      figure
        @apply h-full
        img
          @apply w-full h-full object-cover 
    
    .swiper-button-prev, .swiper-button-next
      @apply w-12 h-12 rounded-full backdrop-blur-sm
      background-color hsla(0, 0, 0, .2)
      backdrop-filter blur(3px)
      &::after
        font-size: 20px
        color white
</style>
