<script setup lang="ts">
import type { IImage } from 'shared'
import { ArrowLongLeftIcon } from '@heroicons/vue/24/outline'

const props = defineProps<{
  name: string,
  longName: string,
  desc?: string,
  image: IImage,
  count: number,
  category: string,
}>()

const image = computed(() => imageUrl(`/category/${props.image?.file?.filename}_sm.webp`))
</script>

<template lang="pug">
NuxtLink.category-item(
  :to="`/products?categories=${category}`"
  class="group relative p-4 rounded-lg bg-white border grid gap-4 grid-cols-[auto_1fr] hover:border-green-400"
  class="transition-border duration-300"
)
  .icon(
    :style="{ 'background-image': `url(${image})` }"
    class="size-24 p-1 bg-no-repeat bg-cover rounded-lg bg-center"
  )
  .details(class="grid gap-2 grid-rows-[auto_1fr]")
    .title(
      class="text-lg flex-grow whitespace-nowrap font-bold"
      class="group-hover:font-semibold group-hover:text-emerald-600"
      class="transition-border duration-200"
    ) {{ longName }}
    .desc(class="text-sm") {{ desc }}
  .count(
    class="transition-border duration-300"
    class="absolute -bottom-5 left-5 h-10 rounded-full px-4 text-sm"
    class="flex items-center justify-center"
    class="text-white bg-red-400 shrink-0 font-semibold leading-none"
    class="hover:shadow hover:shadow-green-300 hover:border-emerald-400 hover:bg-teal-100"
    class="group-hover:bg-emerald-400 group-hover:text-white"
  ) 
    div(class="flex items-center gap-2")
      span {{ count }}
      span محصول
      ArrowLongLeftIcon(class="h-5")
</template>
