<script setup lang="ts">
const modules = [
  SwiperAutoplay,
  SwiperPagination,
  SwiperNavigation,
]
</script>

<template lang="pug">
.home-slider-sm(class="h-[18rem]")
  Swiper(
    :modules="modules"
    :pagination="{ clickable: true }"
    :slidesPerView="'auto'"
    :loop="true"
    :navigation="true"
    :autoplay="{ delay: 6000 }",
    :grabCursor="true"
    :centeredSlides="true"
    class="h-full"
  )
    SwiperSlide
      a(href="#")
        figure
          img(src="/slides/slide-01.webp" loading="lazy")
    SwiperSlide
      a(href="#")
        figure
          img(src="/slides/slide-02.webp" loading="lazy")
    SwiperSlide
      a(href="#")
        figure
          img(src="/slides/slide-03.webp" loading="lazy")
    SwiperSlide
      a(href="#")
        figure
          img(src="/slides/slide-04.webp" loading="lazy")
    SwiperSlide
      a(href="#")
        figure
          img(src="/slides/slide-05.webp" loading="lazy")
</template>

<style lang="stylus">
.home-slider-sm
  --swiper-theme-color #555
  --swiper-pagination-bullet-horizontal-gap 3px
  --swiper-pagination-bullet-size 7px
  --swiper-pagination-bottom 0
  .swiper
    .swiper-slide
      @apply h-full overflow-hidden
      max-width calc(1140px - 2rem)
      figure
        @apply h-full
        img
          @apply w-full h-full object-cover 
    
    .swiper-button-prev, .swiper-button-next
      @apply w-10 h-10 rounded-full backdrop-blur
      background-color hsla(0, 0, 0, .2)
      &::after
        font-size: 8px
        color white
</style>
