<script setup lang="ts">
import type { IHomePage } from 'shared/dist/entities/pages'

useHead({
  title: 'صفحه اصلی',
  link: [getCanonicalLink()],
})

useSeoMeta(getSeoMeta('لمونو - راهنمای سایت'))

const { data: home } = await useApi<IHomePage>('pages/home')
</script>

<template lang="pug">
.home-page
  HomeSlider

  template(v-if="home")
    div(class="container mx-auto")
      SectionTitle(class="my-16" title="انواع محصولات")
      HomeCategoryList(:items="home.productCountPerCategory")

    div(class="container mx-auto")
      SectionTitle(class="my-16" title="خدمات لمونو")
    HomeBenefits(class="mt-12")

    div(class="container mx-auto")
      SectionTitle(class="my-16" title="محصولات پرفروش")
      HomeProductList(:list="home.showcaseProducts")

  HomeServices(class="my-24")

  template(v-if="home")
    div(class="container mx-auto")
      SectionTitle(class="my-16" title="آخرین مقالات")
      HomeArticleList(:list="home.latestArticles")
</template>
