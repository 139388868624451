<script setup lang="ts">
const SliderLG = resolveComponent('HomeSliderLG')
const SliderSM = resolveComponent('HomeSliderSM')

const slider = shallowRef()

slider.value = useDevice().isMobile
  ? SliderSM
  : SliderLG
</script>

<template lang="pug">
.slider(class="block mx-auto h-[18rem] max-w-[1920px] md:h-[35rem]")
  component(:is="slider")
</template>
